import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';

const PBRejectCard = () => {
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  return (
    <Card className={isTab ? 'w-100' : 'w-50'}>
      <CardBody>
        <div className="d-flex align-items-center">
          <h3 className="mb-0">
            <FontAwesomeIcon className="text-danger me-2" icon={faTriangleExclamation} />
            Please select a new brokerage
          </h3>
        </div>
        <p className="font-size-18 my-4 semi-bold mb-3">
          The brokerage you enrolled to has either did not approve your request or removed you from
          their roster. Please enrol to your new brokerage.
        </p>
      </CardBody>
    </Card>
  );
};

export default PBRejectCard;
