import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import { Card, CardBody, Label } from 'reactstrap';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';

const BrokerRequiredCard = () => {
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  return (
    <Card className={isTab ? 'w-100' : 'w-60'}>
      <CardBody>
        <h3 className="mb-4">Brokerage Required</h3>
        <p>
          We have removed your from your previous brokerage. Please complete the "Brokerage / Agent
          Info" section and add your new brokerage to continue.
        </p>
        <Label className=" d-flex align-items-center">
          <BsInfoCircle className="me-2" color="#0099FF" />
          Waiting on you to add a brokerage
        </Label>
      </CardBody>
    </Card>
  );
};

export default BrokerRequiredCard;
