import React from 'react';
import { REJECTED_KEY } from 'constant';
import PBRejectCard from './components/PBRejectCard';
import AdminRejectCard from './components/AdminRejectCard';

const RejectedCard = ({ user, code }) => {
  const { approvedStatus, requestStatus, ...rest } = user;
  if (code || code === 1) {
    return null;
  }

  if (approvedStatus === REJECTED_KEY) {
    return <AdminRejectCard user={{ ...rest }} />;
  }
  if (requestStatus === REJECTED_KEY) {
    return <PBRejectCard user={{ ...rest }} />;
  }
  return null;
};

export default RejectedCard;
