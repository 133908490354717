import {
  AGENT_KEY,
  FILOGIX_INTEGRATION_KEY,
  INVESTOR_KEY,
  MORTGAGE_ADMIN,
  PRINCIPAL_BROKER
} from 'constant';
import {
  PERSONAL_INFO_KEY,
  ACCOUNT_KEY,
  LENDING_CRITERIA_KEY,
  BUSINESS_INFO_KEY,
  BROKERAGE_DETAILS_KEY,
  E_SIGN_KEY,
  FINAL_APPROVAL_KEY,
  MORTGAGE_ADMIN_KEY,
  REQUIRED,
  INVESTOR_ROLE_KEY,
  MA_ROLE_KEY,
  BROKER_ROLE_KEY,
  PB_ROLE_KEY
} from 'constant';

const completeProfileContents = {
  [INVESTOR_ROLE_KEY]: [
    { name: 'Email Confirmation', code: 1, key: 'email-confirmation', path: 'email-verify' },
    {
      name: 'Personal Information',
      code: 2,
      key: 'personal-information',
      path: 'personal-info'
    },
    { name: 'Accounts', code: 3, key: 'accounts', path: 'accounts' },
    { name: 'Lending Criteria', code: 4, key: 'lending-criteria', path: 'lending-criteria' },
    { name: 'Confirm and sign', code: 5, key: 'confirm-and-sign', path: 'e-sign' }
  ],
  [MA_ROLE_KEY]: [
    { name: 'Email Confirmation', code: 1, key: 'email-confirmation', path: 'email-verify' },
    { name: 'Business Information', code: 2, key: 'business-information', path: 'business-info' },
    { name: 'Lending Criteria', code: 3, key: 'lending-criteria', path: 'lending-criteria' },
    { name: 'Confirm and sign', code: 4, key: 'confirm-and-sign', path: 'e-sign' }
  ],
  [BROKER_ROLE_KEY]: [
    { name: 'Email Confirmation', code: 1, key: 'email-confirmation', path: 'email-verify' },
    { name: 'Personal Details', code: 2, key: 'personal-detial', path: 'personal-details' },
    { name: 'Brokerage Details', code: 3, key: 'brokerage-details', path: 'brokerage' },
    { name: 'Confirm and sign', code: 4, key: 'confirm-and-sign', path: 'e-sign' }
  ],
  [PB_ROLE_KEY]: [
    { name: 'Email Confirmation', code: 1, key: 'email-confirmation', path: 'email-verify' },
    { name: 'Personal Details', code: 2, key: 'personal-detial', path: 'personal-details' },
    { name: 'Brokerage Details', code: 3, key: 'brokerage-details', path: 'brokerage-details' },
    { name: 'Confirm and sign', code: 4, key: 'confirm-and-sign', path: 'e-sign' }
  ]
};

const profileContents = {
  [INVESTOR_ROLE_KEY]: [
    { name: 'Personal Information', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-info' },
    { name: 'Accounts', code: 2, key: ACCOUNT_KEY, path: 'accounts' },
    { name: 'Lending Criteria', code: 3, key: LENDING_CRITERIA_KEY, path: 'lending-criteria' },
    { name: 'Mortgage Admin', code: 4, key: MORTGAGE_ADMIN_KEY, path: 'mortgage-admin' }
  ],
  [MA_ROLE_KEY]: [
    { name: 'Personal Information', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-information' },
    { name: 'Business Information', code: 2, key: BUSINESS_INFO_KEY, path: 'business-info' },
    { name: 'Lending Criteria', code: 3, key: LENDING_CRITERIA_KEY, path: 'lending-criteria' },
    { name: 'Investors', code: 4, path: 'investors' }
  ],
  [BROKER_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-details' },
    { name: 'Brokerage / Agent Info', code: 2, key: BROKERAGE_DETAILS_KEY, path: 'brokerage' }
  ],
  [PB_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-details' },
    {
      name: 'Brokerage / Agent Info',
      code: 2,
      key: BROKERAGE_DETAILS_KEY,
      path: 'brokerage-details'
    }
  ]
};

const approveContentCommon = [
  { name: 'Signature', code: 5, key: E_SIGN_KEY, path: 'e-sign' },
  { name: 'Final Approval', code: 6, key: FINAL_APPROVAL_KEY, path: 'final-approval' }
];

const approveContents = {
  [INVESTOR_ROLE_KEY]: [...profileContents[INVESTOR_ROLE_KEY], ...approveContentCommon],
  [MA_ROLE_KEY]: [
    { name: 'Personal Information', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-information' },
    { name: 'Business Information', code: 2, key: BUSINESS_INFO_KEY, path: 'business-info' },
    { name: 'Lending Criteria', code: 3, key: LENDING_CRITERIA_KEY, path: 'lending-criteria' },
    { name: 'Signature', code: 4, key: E_SIGN_KEY, path: 'e-sign' },
    { name: 'Final Approval', code: 5, key: FINAL_APPROVAL_KEY, path: 'final-approval' }
  ],
  [BROKER_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-information' },
    { name: 'Brokerage / Agent Info', code: 2, key: BROKERAGE_DETAILS_KEY, path: 'brokerage-info' },
    { name: 'Signature', code: 3, key: E_SIGN_KEY, path: 'e-sign' },
    { name: 'Final Approval', code: 4, key: FINAL_APPROVAL_KEY, path: 'final-approval' }
  ],
  [PB_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-details' },
    {
      name: 'Brokerage / Agent Info',
      code: 2,
      key: BROKERAGE_DETAILS_KEY,
      path: 'brokerage-details'
    },
    { name: 'Signature', code: 3, key: E_SIGN_KEY, path: 'e-sign' },
    { name: 'Final Approval', code: 4, key: FINAL_APPROVAL_KEY, path: 'final-approval' }
  ]
};

const usersContent = {
  [INVESTOR_ROLE_KEY]: [
    ...profileContents[INVESTOR_ROLE_KEY],
    { name: 'Signature', code: 3, key: E_SIGN_KEY, path: 'e-sign' }
  ],
  [MA_ROLE_KEY]: [
    ...profileContents[MA_ROLE_KEY],
    { name: 'Signature', code: 4, key: E_SIGN_KEY, path: 'e-sign' }
  ],
  [BROKER_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-details' },
    { name: 'Brokerage / Agent Info', code: 2, key: BROKERAGE_DETAILS_KEY, path: 'brokerage' },
    { name: 'Signature', code: 3, key: E_SIGN_KEY, path: 'e-sign' },
    {
      name: 'Filogix Integration',
      code: 4,
      key: FILOGIX_INTEGRATION_KEY,
      path: 'filogix-integration'
    }
  ],
  [PB_ROLE_KEY]: [
    { name: 'Personal Details', code: 1, key: PERSONAL_INFO_KEY, path: 'personal-details' },
    {
      name: 'Brokerage / Agent Info',
      code: 2,
      key: BROKERAGE_DETAILS_KEY,
      path: 'brokerage-details'
    },
    { name: 'Signature', code: 3, key: E_SIGN_KEY, path: 'e-sign' },
    {
      name: 'Filogix Integration',
      code: 4,
      key: FILOGIX_INTEGRATION_KEY,
      path: 'filogix-integration'
    }
  ]
};

const getProgressBarNumber = (contents) => {
  const filter = contents.filter((item) => item);
  if (!filter.length) return 5;
  let num = 100 / contents.length;
  return num * filter.length;
};

const getProgress = (user, role) => {
  switch (role) {
    case INVESTOR_KEY:
      return getInvestorProgress(user);
    case AGENT_KEY:
      return getAgentProgress(user);
    case PRINCIPAL_BROKER:
      return getPBProgress(user);
    case MORTGAGE_ADMIN:
      return getMaProgress(user);
    default:
      return '5';
  }
};

const getAgentProgress = (user) => {
  const { isEmailVerified, isPersonalInfo, isBrokerageInfo, isEsign } = user;
  const e_sign = isBrokerageInfo ? isEsign : false;
  return getProgressBarNumber([isEmailVerified, isPersonalInfo, isBrokerageInfo, e_sign]);
};

const getInvestorProgress = (user) => {
  const { isEmailVerified, isPersonalInfo, isAccountVerified, isLendingCreteria, isEsign } = user;
  return getProgressBarNumber([
    isEmailVerified,
    isPersonalInfo,
    isAccountVerified,
    isLendingCreteria,
    isEsign
  ]);
};

const getPBProgress = (user) => {
  const { isEmailVerified, isPersonalInfo, isBrokerageInfo, isEsign } = user;
  return getProgressBarNumber([isEmailVerified, isPersonalInfo, isBrokerageInfo, isEsign]);
};

const getMaProgress = (user) => {
  const { isEmailVerified, isBusinessInfo, isLendingCreteria, isEsign } = user;
  return getProgressBarNumber([isEmailVerified, isBusinessInfo, isLendingCreteria, isEsign]);
};

const trackInvestor = (userData) => {
  const { isPersonalInfo, isAccountVerified, isLendingCreteria, isEsign } = userData;
  if (!isPersonalInfo) {
    return '/complete-profile/personal-info';
  }
  if (!isAccountVerified) {
    return '/complete-profile/accounts';
  }
  if (!isLendingCreteria) {
    return '/complete-profile/lending-criteria';
  }
  if (!isEsign) {
    return '/complete-profile/e-sign';
  }
};

const trackMA = (userData) => {
  const { isBusinessInfo, isLendingCreteria, isEsign } = userData;
  if (!isBusinessInfo) {
    return '/complete-profile/business-info';
  }
  if (!isLendingCreteria) {
    return '/complete-profile/lending-criteria';
  }
  if (!isEsign) {
    return '/complete-profile/e-sign';
  }
};

const trackPB = (userData) => {
  const { isPersonalInfo, isBrokerageInfo, isEsign } = userData;
  if (!isPersonalInfo) {
    return '/complete-profile/personal-details';
  }
  if (!isBrokerageInfo) {
    return '/complete-profile/brokerage-details';
  }
  if (!isEsign) {
    return '/complete-profile/e-sign';
  }
};

const trackAGENT = (userData) => {
  const { isPersonalInfo, isBrokerageInfo, isEsign } = userData;
  if (!isPersonalInfo) {
    return '/complete-profile/personal-details';
  }
  if (!isBrokerageInfo) {
    return '/complete-profile/brokerage';
  }
  if (!isEsign) {
    return '/complete-profile/e-sign';
  }
};

const getGetStartedCode = (userData, role) => {
  switch (role) {
    case INVESTOR_KEY:
      return trackInvestor(userData);
    case MORTGAGE_ADMIN:
      return trackMA(userData);
    case PRINCIPAL_BROKER:
      return trackPB(userData);
    case AGENT_KEY:
      return trackAGENT(userData);
    default:
      break;
  }
};

const getSecondRouteOfRole = (key) => {
  switch (key) {
    case INVESTOR_ROLE_KEY:
      return `/complete-profile/personal-info`;
    case MA_ROLE_KEY:
      return `/complete-profile/business-info`;
    case PB_ROLE_KEY:
      return `/complete-profile/personal-details`;
    case BROKER_ROLE_KEY:
      return `/complete-profile/personal-details`;
    default:
      break;
  }
};

export {
  getProgress,
  getGetStartedCode,
  completeProfileContents,
  profileContents,
  approveContents,
  getSecondRouteOfRole,
  usersContent
};
