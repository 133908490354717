import { gql } from '@apollo/client';

const ADD_BROKER_DETAILS = gql`
  mutation AddBrokerDetails(
    $userId: Int
    $bussinessType: String
    $principaBrokerName: String
    $brokerageCompanyName: String
    $brokerageCompanyId: Int
    $principalBrokerEmail: String
  ) {
    addBrokerDetails(
      userId: $userId
      bussinessType: $bussinessType
      principaBrokerName: $principaBrokerName
      brokerageCompanyName: $brokerageCompanyName
      brokerageCompanyId: $brokerageCompanyId
      principalBrokerEmail: $principalBrokerEmail
    )
  }
`;

const GET_BROKER_DETAILS = gql`
  query getBrokerDetails($userId: Int!) {
    getBrokerDetails(userId: $userId)
  }
`;

const REMOVE_BROKERAGE = gql`
  mutation RemoveBrokerage($brokerId: Int!, $pbId: Int!, $brokerageId: Int!) {
    removeBrokerage(brokerId: $brokerId, pbId: $pbId, brokerageId: $brokerageId)
  }
`;

const GET_BROKERAGE_COMPANIES = gql`
  query getBrokerageCompanies {
    getBrokerageCompanies
  }
`;

const ADD_INVITATION_REQUEST = gql`
  mutation AddInvitationsRequests(
    $invitedBy: Int!
    $roleId: Int!
    $inviteRequestType: String!
    $invitedTo: Int
    $email: String
    $name: String
  ) {
    addInvitationsRequests(
      invitedBy: $invitedBy
      roleId: $roleId
      inviteRequestType: $inviteRequestType
      invitedTo: $invitedTo
      email: $email
      name: $name
    )
  }
`;

export {
  ADD_BROKER_DETAILS,
  GET_BROKER_DETAILS,
  REMOVE_BROKERAGE,
  GET_BROKERAGE_COMPANIES,
  ADD_INVITATION_REQUEST
};
