import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { useMutation } from '@apollo/client';
import { REMOVE_BROKERAGE } from '../BrokerGraphQL';
import { getUser } from 'utils/UserDetails';
import { toast } from 'react-toastify';
import trangleImg from 'assets/icons/danger.png';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';

const LeaveBrokerageModal = ({ brokerage, open, close, onRemoveBrokerage }) => {
  const [removeBrokerage, { loading }] = useMutation(REMOVE_BROKERAGE);
  const user = getUser();
  const navigate = useNavigate();
  const onSubmit = async (data, action) => {
    const { setFieldError } = action;
    const brokerageName = brokerage?.brokerageCompanyName
      ? brokerage?.brokerageCompanyName
      : brokerage?.principaBrokerName;
    if (data.brokerageName !== brokerageName?.trim())
      return setFieldError('brokerageName', 'Please match name');
    try {
      const { data: result } = await removeBrokerage({
        variables: {
          pbId: brokerage.brokerageCompanyId || 0,
          brokerId: user.id,
          brokerageId: brokerage.id
        }
      });
      toast.success(result?.removeBrokerage);
      onRemoveBrokerage();
      close();
      navigate('/dashboard');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Modal
        isOpen={open}
        toggle={close}
        className="modal-dialog-centered"
        style={{ maxWidth: '520px' }}>
        <ModalBody className="p-4">
          <h4 className="mb-4 d-flex">
            <img src={trangleImg} height={22} className="me-2" />
            Leave Brokerage
          </h4>
          <div className="mb-4 modal-content-text">
            Are you sure you want to leave{' '}
            <span className="fw-bold">
              {brokerage?.brokerageCompanyName
                ? brokerage?.brokerageCompanyName
                : brokerage?.principaBrokerName}
            </span>
            {'? '}
            You will lose access to all of your opportunities if you proceed.
          </div>
          <Formik
            initialValues={{ brokerageName: '' }}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              brokerageName: Yup.string().trim().required('Required')
            })}
            enableReinitialize={true}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} lg={12}>
                    <Field
                      size="lg"
                      id="brokerageName"
                      name="brokerageName"
                      type="text"
                      placeholder={
                        brokerage?.brokerageCompanyName
                          ? brokerage?.brokerageCompanyName
                          : brokerage?.principaBrokerName
                      }
                      component={FormikInputField}
                      label="Type the name of the brokerage to confirm leaving"
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-4">
                  <Button type="button" onClick={close} outline size="sm" disabled={loading}>
                    Cancel
                  </Button>
                  {loading ? (
                    <Loader className="ms-3" />
                  ) : (
                    <Button
                      type="submit"
                      color="danger"
                      className="ms-3"
                      size="sm"
                      disabled={loading}>
                      Leave
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeaveBrokerageModal;
