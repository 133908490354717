import { PENDING_KEY, REJECTED_KEY } from 'constant';
import ProfilePendingCard from 'pages/Profile/common/Submission/ProfilePendingCard';
import React from 'react';
import WaitingOnPB from './WaitingOnPB';
import AdminRejectCard from './components/AdminRejectCard';

const SubmittingFlow = ({ user }) => {
  const { approvedStatus, requestStatus, ...rest } = user;
  if (approvedStatus === REJECTED_KEY) {
    return <AdminRejectCard userDetails={{ ...rest }} />;
  }
  if (approvedStatus === PENDING_KEY) {
    return <ProfilePendingCard userPersonalDetails={{ ...rest }} />;
  }

  return <WaitingOnPB user={{ ...rest }} />;
};

export default SubmittingFlow;
