import React from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import infoIcon from 'assets/icons/info.svg';
import { useQuery } from '@apollo/client';
import { GET_BROKER_DETAILS } from '../BrokerGraphQL';

const WaitingOnPB = ({ user }) => {
  const { data } = useQuery(GET_BROKER_DETAILS, {
    variables: {
      userId: Number(user.id)
    },
    fetchPolicy: 'no-cache'
  });
  const broker = data?.getBrokerDetails ? data?.getBrokerDetails : {};

  const getPBName = () => {
    if (broker?.brokerageCompanyName) return broker?.brokerageCompanyName;
    if (broker?.principaBrokerName) return broker?.principaBrokerName;
    return null;
  };

  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {user.preferredName}!</h2>
      <Row>
        <Col md={6} lg={6}>
          <Card>
            <CardBody>
              <h3>Waiting on your Principal Broker to confirm your enrollment</h3>
              <p className="font-size-18 my-4 semi-bold">
                Thank you for completing your profile. Your profile is currently sitting with the
                Principal Broker you enrolled with and awaiting their confirmation. Once that is
                completed, you can start creating opportunities.
              </p>
              <Label className=" d-flex align-items-center">
                <img
                  src={infoIcon}
                  className="me-2"
                  alt="info icon"
                  style={{ height: '20px', width: '20px' }}
                />
                Waiting on {getPBName()} to approve.
              </Label>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WaitingOnPB;
