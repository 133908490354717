import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Card, CardBody } from 'reactstrap';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';

const OnboardCard = () => {
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  return (
    <Card className={isTab ? 'w-100' : 'w-50'}>
      <CardBody>
        <h3 className="mb-4">Let's get you onboarded</h3>
        <p>
          Before we can fully unlock the full potential of Openfund for you, we will need to get
          your fully onboarded. Due to the sensitive nature of the information available on this
          platform, we require additional information.
        </p>
        <p>
          Please click on <span className="text-primary fw-500">"Get Started"</span> to continue.
        </p>
      </CardBody>
    </Card>
  );
};

export default OnboardCard;
