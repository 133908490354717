import * as Yup from 'yup';

const brokerValidation = Yup.object({
  brokerageCompany: Yup.string().nullable().required('Required')
});

const brokerEnhanceValidation = Yup.object({
  brokerageCompany: Yup.string().required('Required'),
  principaBrokerName: Yup.string()
    .nullable()
    .trim()
    .required('Required')
    .min(3, 'Please enter valid name')
    .max(30, 'Please enter valid name'),
  principalBrokerEmail: Yup.string()
    .nullable()
    .trim()
    .required('Required')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email'
    )
});

const brokerInitValue = {
  brokerageCompany: '',
  principaBrokerName: '',
  principalBrokerEmail: ''
};

export { brokerInitValue, brokerValidation, brokerEnhanceValidation };
