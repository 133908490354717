import React from 'react';
import { useQuery } from '@apollo/client';
import Avatar from 'components/Avatar';
import { GET_BROKER_DETAILS } from '../../../BrokerGraphQL';
import { Card, CardBody } from 'reactstrap';

const SelectedBroker = ({ userId }) => {
  const { data } = useQuery(GET_BROKER_DETAILS, {
    variables: {
      userId: Number(userId)
    },
    fetchPolicy: 'no-cache'
  });

  const broker = data?.getBrokerDetails ? data?.getBrokerDetails : {};

  return (
    <Card className="mb-4">
      <CardBody className="p-4">
        <h3 className="mb-3">Brokerage</h3>
        <div className="d-flex justify-content-between p-4 mb-3" style={{ background: '#F8F8F8' }}>
          <div className="d-flex align-items-center">
            <div className="me-3">
              <Avatar
                image={broker.logo}
                fname={broker?.brokerageCompanyName || broker.principaBrokerName}
                size={45}
                fontsize="20"
              />
            </div>
            <div>
              <h5 className="font-size-16 mb-1">
                <span className="font-size-18">
                  {broker?.brokerageCompanyName
                    ? broker?.brokerageCompanyName
                    : broker.principaBrokerName}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SelectedBroker;
