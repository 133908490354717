import React, { useContext, useState } from 'react';
import { Field, Formik } from 'formik';
import { Button, Card, CardBody } from 'reactstrap';
import BrokerDetails from './BrokerDetails';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import { brokerInitValue, brokerValidation, brokerEnhanceValidation } from '../Helper';
import { getUser } from 'utils/UserDetails';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_BROKER_DETAILS,
  GET_BROKER_DETAILS,
  GET_BROKERAGE_COMPANIES,
  ADD_INVITATION_REQUEST
} from '../BrokerGraphQL';
import { toast } from 'react-toastify';
import Brokerage from '../Brokerage';
import Loader from 'components/Loader';
import { PB_ROLE_KEY, REQUEST_TYPE } from 'constant';
import CombineErrorMessage from 'components/CombineError';
import { RoleContext } from 'context/Role';
import { filter } from 'lodash';
import PageLoader from 'components/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';

const BrokerageDetailsContainer = (props) => {
  const { data, error, loading } = useQuery(GET_BROKERAGE_COMPANIES);

  if (loading) {
    return <PageLoader />;
  }

  const brokerageCompanies = data && data.getBrokerageCompanies ? data.getBrokerageCompanies : [];

  return <BrokerageDetails brokerageCompanies={brokerageCompanies} {...props} />;
};

const BrokerageDetails = ({ updateContent, brokerageCompanies }) => {
  const [state, setState] = useState({
    validation: brokerValidation,
    hasError: false
  });
  const { data: rolesList } = useContext(RoleContext);
  const navigate = useNavigate();
  const user = getUser();
  const [addBrokerDetails, { loading }] = useMutation(ADD_BROKER_DETAILS);
  const [sendRequestToPB] = useMutation(ADD_INVITATION_REQUEST);
  const { id } = useParams();
  const {
    data: brokerDetails,
    error,
    loading: getBrokerLoading,
    refetch
  } = useQuery(GET_BROKER_DETAILS, {
    variables: {
      userId: Number(id || user.id)
    },
    fetchPolicy: 'no-cache'
  });

  const { validation, hasError } = state;

  const updateValidation = (flag) => {
    setState((pre) => ({
      ...pre,
      validation: flag ? brokerEnhanceValidation : brokerValidation
    }));
  };

  const findRoleIdByKey = (key) => {
    const filterParamKey = filter(rolesList?.rolesList.roles, (item) => item.roleKey === key);
    if (filterParamKey.length) {
      return filterParamKey[0].id;
    }
  };

  const sendRequest = async (id) => {
    try {
      const result = await sendRequestToPB({
        variables: {
          invitedBy: user.id,
          invitedTo: id,
          inviteRequestType: REQUEST_TYPE,
          roleId: findRoleIdByKey(PB_ROLE_KEY),
          email: user.email
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSubmit = async (data) => {
    const { brokerageCompany } = data;
    const findBrokerage = getBrokerageOptions().find((option) => {
      return option.companyId === parseInt(brokerageCompany);
    });
    // if (findBrokerage?.companyId) {
    //   sendRequest(findBrokerage.companyId);
    // }
    try {
      const castValues = validation.cast(data);
      const { data: result } = await addBrokerDetails({
        variables: {
          userId: user.id,
          ...castValues,
          brokerageCompanyName: findBrokerage?.companyName,
          brokerageCompanyId: findBrokerage?.companyId
        }
      });
      toast.success(result.addBrokerDetails.message);
      navigate('/complete-profile/e-sign');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const onRemoveBrokerage = () => {
    refetch({
      userId: user.id
    });
    // updateContent();
  };

  if (brokerDetails?.getBrokerDetails && !error) {
    return <Brokerage data={brokerDetails} onRemoveBrokerage={onRemoveBrokerage} />;
  }

  const getBrokerageOptions = () => {
    const companies = [];

    if (brokerageCompanies.length) {
      brokerageCompanies.forEach((company) => {
        let companyName = '';
        if (company?.principal_brokerage_detail) {
          if (company.principal_brokerage_detail?.operatingName) {
            companyName = company.principal_brokerage_detail?.operatingName;
          } else if (company.principal_brokerage_detail.legalBusinessName) {
            companyName = company.principal_brokerage_detail.legalBusinessName;
          } else {
            companyName = `${company.firstName} ${company.lastName}`;
          }
          companies.push({ companyId: company.id, companyName: companyName });
        }
      });
    }
    return companies;
  };

  const updateError = (err) => {
    return setState((pre) => ({
      ...pre,
      hasError: err
    }));
  };

  if (getBrokerLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Formik
        initialValues={brokerInitValue}
        enableReinitialize={true}
        validationSchema={validation}
        onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardBody>
                {hasError && (
                  <CombineErrorMessage>
                    Please ensure you enter all required fields
                  </CombineErrorMessage>
                )}
                <h3 className="card-heading">Brokerage</h3>
                <Field
                  size="lg"
                  type="select"
                  name="brokerageCompany"
                  label="Brokerage Company"
                  options={[...getBrokerageOptions()]}
                  component={FormikSelectField}
                  keys={{
                    id: 'companyId',
                    label: 'companyName'
                  }}
                  isError={false}
                />
                <BrokerDetails updateError={updateError} updateValidation={updateValidation} />
              </CardBody>
            </Card>
            <div className="mt-3 text-end">
              {loading ? (
                <Loader size="lg" />
              ) : (
                <Button color="primary" type="submit" size="lg">
                  Save and continue
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BrokerageDetailsContainer;
