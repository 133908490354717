import React, { useState } from 'react';
import Avatar from 'components/Avatar';
import { Button, Card, CardBody } from 'reactstrap';
import LeaveBrokerageModal from './LeaveBrokerageModal';
import { useNavigate, useParams } from 'react-router-dom';

const Brokerage = ({ data, onRemoveBrokerage }) => {
  const [open, setOpen] = useState(false);
  const { getBrokerDetails: broker } = data;
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleModal = () => {
    setOpen((pre) => !pre);
  };

  const onHandleClick = () => {
    if (id) {
      return navigate(`/user/broker-agent/${id}/e-sign`);
    } else {
      return navigate(`/complete-profile/e-sign`);
    }
  };

  return (
    <>
      <Card>
        <CardBody className="p-4">
          <h3 className="mb-3">Brokerage</h3>
          <div
            className="d-flex justify-content-between p-4 mb-3"
            style={{ background: '#F8F8F8' }}>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <Avatar
                  image={broker.logo}
                  fname={broker?.brokerageCompanyName || broker.principaBrokerName}
                  size={45}
                  fontsize="20"
                />
              </div>
              <div>
                <h5 className="font-size-16 mb-1">
                  <span className="font-size-18">
                    {broker?.brokerageCompanyName
                      ? broker?.brokerageCompanyName
                      : broker.principaBrokerName}
                  </span>
                </h5>
              </div>
            </div>
            <div>
              <Button onClick={toggleModal} color="link" className="p-0 font-size-14 mt-2">
                <span className="text-danger">Remove</span>
              </Button>
            </div>
          </div>
        </CardBody>

        <LeaveBrokerageModal
          brokerage={broker}
          open={open}
          close={toggleModal}
          onRemoveBrokerage={onRemoveBrokerage}
        />
      </Card>
      <div className="text-end" onClick={onHandleClick}>
        <Button color="primary" type="submit" className="mt-4 semi-bold" size="lg">
          Save and continue
        </Button>
      </div>
    </>
  );
};

export default Brokerage;
