import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';

const BrokerDetails = ({ updateValidation, updateError }) => {
  const { errors, touched, setFieldValue, values, setErrors } = useFormikContext();

  useEffect(() => {
    const err = checkError();
    updateError(err);
  }, [errors, touched, values.brokerageCompany]);

  const checkError = () => {
    return (
      (errors.principaBrokerName && touched.principaBrokerName) ||
      (errors.principalBrokerEmail && touched.principalBrokerEmail) ||
      (errors.brokerageCompany && touched.brokerageCompany)
    );
  };
  useEffect(() => {
    if (values.brokerageCompany === '0') {
      updateValidation(true);
    }
    if (values.brokerageCompany !== '0') {
      updateValidation(false);
      setTimeout(() => setErrors({}), 100);
      setFieldValue('principaBrokerName', null);
      setFieldValue('principalBrokerEmail', null);
    }
  }, [values.brokerageCompany]);

  return (
    values.brokerageCompany === '0' && (
      <>
        <div className="mb-3">
          <Field
            size="lg"
            type="text"
            name="principaBrokerName"
            label="Principal Broker Name"
            component={FormikInputField}
            isError={false}
          />
        </div>
        <Field
          size="lg"
          type="text"
          name="principalBrokerEmail"
          label="Principal Broker Email"
          component={FormikInputField}
          isError={false}
        />
      </>
    )
  );
};

export default BrokerDetails;
